import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { Entity, MaintenananceServiceWarrantyItem, MaintenanceServiceAddOnRequestSummary, MaintenanceServiceCustomerPropertyServiceAppointment, MaintenanceServiceCustomerPropertyServiceAppointmentSummary, MaintenanceServiceCustomerPropertyServiceSummary, MaintenanceServiceCustomerPropertySummary, MaintenanceServiceServiceableWarrantyItem } from '@upkeeplabs/models/cogent';
import { ApiService } from '@cogent/client/api';
import { HistoryItemApiService } from '@cogent/client/shared/services/api/history-item-api.service';
import { MaintServicesChangePreferredDayComponent } from '../maint-services-change-preferred-day/maint-services-change-preferred-day.component';
import { MaintServicesCompleteAppointmentComponent } from '../maint-services-complete-appointment/maint-services-complete-appointment.component';
import { MaintServicesCancelAppointmentComponent } from '../maint-services-cancel-appointment/maint-services-cancel-appointment.component';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { StartServiceRequestComponent } from '../start-service-request/start-service-request.component';
import { MaintenanceServiceRescheduleAppointmentComponent } from '@cogent/client/shared/components/maintenance-service/maintenance-service-reschedule-appointment/maintenance-service-reschedule-appointment.component';

@Component({
    selector: 'app-maint-services-customer-view',
    templateUrl: './maint-services-customer-view.component.html',
    styleUrls: ['./maint-services-customer-view.component.css']
})
export class MaintServicesCustomerViewComponent implements OnInit {

    customerProperty: MaintenanceServiceCustomerPropertySummary;
    services: MaintenanceServiceCustomerPropertyServiceSummary[];
    selectedService: MaintenanceServiceCustomerPropertyServiceSummary;
    serviceAppointments: MaintenanceServiceCustomerPropertyServiceAppointmentSummary[];

    cancelledServices: MaintenanceServiceCustomerPropertyServiceSummary[];
    id: string;
    appointmentId: string;
    contractor: Entity;
    addOnRequests: MaintenanceServiceAddOnRequestSummary[];
    warrantyItems: MaintenananceServiceWarrantyItem[];

    constructor(private maintApi: MaintenanceServiceApiService,
        private serviceApi: ServiceApiService,
        private entityApi: EntityApiService,
        private dialog: MatDialog,
        private missionService: MissionService,
        private dialogService: DialogsService,
        private historyApi: HistoryItemApiService,
        private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.entityApi.getLoggedInUser().then(user => {
            this.contractor = user;
            this.activatedRoute.params.subscribe(params => {
                if (params.id) {
                    this.id = params.id;
                    this.refresh();
                }
            });

            this.activatedRoute.queryParams.subscribe(params => {
                if (params.serviceId) {
                    this.appointmentId = params.serviceId;
                }
            });
        });
    }

    startWarrantyRequest(item: MaintenananceServiceWarrantyItem) {
        this.dialog.open(StartServiceRequestComponent, { data: { item, service: this.selectedService } });
    }

    getItemUrl(item: MaintenanceServiceServiceableWarrantyItem) {
        return `${ApiService.endPointNode}scheduled-maintenance/item-photo/${item.workOrderItemId}`;
    }

    reschedule(appointment: MaintenanceServiceCustomerPropertyServiceAppointment) {
        const ref = this.dialog.open(MaintenanceServiceRescheduleAppointmentComponent, {data: {date: appointment.scheduledDate, scheduledStartWindow: appointment.scheduledStartWindow, scheduledEndWindow: appointment.scheduledEndWindow }});
        ref.afterClosed().subscribe(async results=> {
            if(results) {
                await this.maintApi.rescheduleAppointment(appointment, results.newDate, results.notifyCustomer, results.scheduledStartWindow, results.scheduledEndWindow);
                this.missionService.showSuccessToast('Appointment Changed');
                this.select(this.selectedService);
            }
        });
    }

    refresh() {
        this.maintApi.getMaintServiceCustomerPropertyById(this.id).then(async prop => {
            this.customerProperty = prop;
        });


        this.entityApi.getLoggedInUser().then(user => {

            this.maintApi.getAddOnOfferingsByProperty(this.id, user.id).then(addOns => {
                this.addOnRequests = addOns.filter(i => !i.rejectedDate);;
            });
            this.maintApi.getCustomerPropertyServicesByPropertyAndContractor(this.id, user.id).then(async services => {
                this.services = services;
                if (this.appointmentId) {
                    const appointment = await this.maintApi.getAppointmentSummary(this.appointmentId);
                    let service = this.services.find(i => i.id === appointment.maintenanceServiceCustomerPropertyServiceId);
                    if (!service) {
                        service = await this.maintApi.getCustomerPropertyServiceSummary(appointment.maintenanceServiceCustomerPropertyServiceId);
                        if (service) {
                            this.services = [service];
                            this.select(service);
                        }
                    }
                }
            });
            this.maintApi.getCancelledCustomerPropertyServicesByPropertyAndContractor(this.id, user.id).then(cancelled => {
                this.cancelledServices = cancelled;
            });
        });
    }

    getServiceUrl(service: MaintenanceServiceCustomerPropertyServiceSummary) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${service.maintenanceServiceOfferingId}/Photo`;
    }

    getBackground(offering) {
        return `url(${this.serviceApi.getItemThumbnailUrl(offering.maintenanceServiceOfferingId)})`;
    }

    get propertyUrl() {
        if (!this.customerProperty) {
            return null;
        }

        return `${ApiService.endPointDotNet}Address/${this.customerProperty.propertyId}/StreetView`;
    }

    changePreferredDay() {
        const ref = this.dialog.open(MaintServicesChangePreferredDayComponent, { data: this.selectedService });
        ref.afterClosed().subscribe(results => {
            if (results) {
                this.refresh();
                this.selectedService = null;
            }
        });
    }

    editAppointment(appointment: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) {
        const ref = this.dialog.open(MaintServicesCompleteAppointmentComponent, { data: appointment });
        ref.afterClosed().subscribe(results => {
            if (results) {
                //job.completed = true;
                appointment.completedDate = new Date();
            }
        });
        appointment.selected = false;
    }

    cancelAppointment(appointment: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) {
        const ref = this.dialog.open(MaintServicesCancelAppointmentComponent, { data: appointment });
        ref.afterClosed().subscribe(results => {
            if (results) {
                appointment.cancellationDate = new Date();
            }
        });
        appointment.selected = false;
    }

    async uncancelAppointment(appointment: MaintenanceServiceCustomerPropertyServiceAppointmentSummary) {
        const result = await this.dialogService.confirm('Confirm', 'Are you sure you want to un-cancel this appointment?').toPromise();
        if (result) {
            await this.maintApi.uncancelAppointment(appointment.id);
            this.missionService.showSuccessToast('Appointment Un-Cancelled');
            delete appointment.cancellationDate;
            appointment.selected = false;
        }
    }

    getOfferingUrl(offering) {
        return this.serviceApi.getItemThumbnailUrl(offering.maintenanceServiceOfferingId);
    }

    async select(service: MaintenanceServiceCustomerPropertyServiceSummary) {
        this.warrantyItems = [];
        this.selectedService = service;
        this.serviceAppointments = [];

        this.serviceAppointments = await this.maintApi.getMaintenanceServiceCustomerPropertyServiceAppointmentSummaryForService(service.id);
        if (this.contractor) {
            this.serviceAppointments = this.serviceAppointments.filter(i => i.contractorId === this.contractor.id);
        }

        this.maintApi.getMaintenanceServiceWarrantyItems(service.id).then(items => {
            this.warrantyItems = items;
        });
    }
}
