import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { AuthorizationInputArgs, AuthorizationReviewTrigger, WorkOrderItemType } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-select-work-order-item-type',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './select-work-order-item-type.component.html',
    styleUrl: './select-work-order-item-type.component.scss'
})
export class SelectWorkOrderItemTypeComponent implements OnChanges {

    @Input() workOrderItemTypeId: string;
    @Output() workOrderItemTypeIdChange: EventEmitter<string> = new EventEmitter();
    @Input() authoInput: AuthorizationInputArgs;
    types: WorkOrderItemType[];

    constructor(private authApi: AuthorizationsApiService) { }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.authoInput?.currentValue) {
            this.authApi.getWorkOrderItemTypesByWorkOrderItemId(this.authoInput.workOrderLine.itemId).then(types => this.types = types);
        }
    }

    selectType(type: WorkOrderItemType) {
        this.workOrderItemTypeId = type.id;
        if (this.authoInput) {
            this.authoInput.workOrderItemType = type;
        }
        if (this.authoInput.salesItemCoverageWorkOrderItem) {
            let createWarning = false;
            if (type.coveredType === 'Never') {
                createWarning = true;
            } else if (type.coveredType === 'Conditional') {

                if (type.salesItemCoverages.indexOf(this.authoInput.salesItemCoverageWorkOrderItem.salesItemCoverageId) === -1) {
                    createWarning = true;
                }
            }

            if (createWarning) {
                const authoWarning = new AuthorizationReviewTrigger();
                authoWarning.type = 'MANUAL_REVIEW';
                authoWarning.id = UtilitiesService.newid();
                authoWarning.description = `Type: ${type.name} is not covered`;

                if (!this.authoInput.authorizationWarnings) {
                    this.authoInput.authorizationWarnings = [];
                }
                this.authoInput.authorizationWarnings.push(authoWarning);
            }
        }
        this.workOrderItemTypeIdChange.emit(this.workOrderItemTypeId);
    }
}
