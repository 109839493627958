import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { WorkOrderLineAuthorizationRepairItem } from '@upkeeplabs/models/cogent';
import { FunctionQuestionRendererModule } from '../../functions/function-question-renderer/function-question-renderer.module';
import { FunctionRunner } from '../../../../../../../node/logic/functions/function-runner.logic';
import { getAngularExecutors } from '@cogent/client/shared/logic/client-functions.angular.logic';
import { getSharedExecutors } from '../../../../../../../node/logic/functions/shared/control-flow-functions.logic';
import { FunctionQuestionRendererComponent } from '../../functions/function-question-renderer/function-question-renderer/function-question-renderer.component';

@Component({
    selector: 'app-authorization-repair-item-selector',
    standalone: true,
    imports: [FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        CommonModule,
        ReactiveFormsModule,
        FunctionQuestionRendererModule,
        AsyncPipe],
    templateUrl: './authorization-repair-item-selector.component.html',
    styleUrl: './authorization-repair-item-selector.component.scss'
})
export class AuthorizationRepairItemSelectorComponent implements OnChanges {
    @Input() repairItems: WorkOrderLineAuthorizationRepairItem[];
    myControl = new FormControl('');
    filteredOptions: Observable<WorkOrderLineAuthorizationRepairItem[]>;
    @Output() repairItemNameChange: EventEmitter<string> = new EventEmitter();
    @Output() repairItemSelected: EventEmitter<WorkOrderLineAuthorizationRepairItem> = new EventEmitter();
    id = UtilitiesService.newid();
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;
    @ViewChild('functionQuestionRendererComponent') functionQuestionRendererComponent: FunctionQuestionRendererComponent;
    showQuestions = false;
    @Input() gettingAttributes = false;
    @Output() gettingAttributesChange: EventEmitter<boolean> = new EventEmitter();
    @Output() attributesComplete: EventEmitter<WorkOrderLineAuthorizationRepairItem> = new EventEmitter();
    @Input() workOrderItemTypeId: string;

    focus() {
        if (!document.getElementById(this.id)) {
            setTimeout(() => this.focus(), 100);
            return;
        }
        document.getElementById(this.id).focus();
    }

    ngOnInit(): void {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '')),
        );
    }



    private _filter(value: string): WorkOrderLineAuthorizationRepairItem[] {
        this.repairItemNameChange.emit(value);
        this.gettingAttributes = false;
        this.gettingAttributesChange.emit(this.gettingAttributes);
        this.showQuestions = false;


        if (!this.repairItems) {
            return [];
        }
        const filterValue = value.toLowerCase();

        return this.repairItems.filter(option => option.name.toLowerCase().includes(filterValue)
            || option.aliasList.find(alias => alias && alias.toLowerCase().includes(filterValue)));
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.workOrderItemTypeId?.currentValue) {
            this.filterRepairItemsByWorkOrderItemType();
        }
        if (changes.repairItems?.currentValue) {
            this.filterRepairItemsByWorkOrderItemType();
        }
    }

    private filterRepairItemsByWorkOrderItemType() {
        if (!this.repairItems || !this.workOrderItemTypeId) {
            return;
        }
        this.repairItems = this.repairItems.filter(i => !i.restrictByWorkOrderItemTypes || (i.workOrderItemTypes && i.workOrderItemTypes.indexOf(this.workOrderItemTypeId) > -1));
    }

    optionSelected(value) {
        const id = value.option.id;

        const repairItem = this.repairItems.find(i => i.id === id);
        this.repairItemSelected.emit(repairItem);

        this.showQuestions = false;
        this.gettingAttributes = false;
        this.gettingAttributesChange.emit(this.gettingAttributes);
        delete repairItem.selectedAttributes;
        if (repairItem.attributesDefinition) {
            setTimeout(() => {
                this.showQuestions = true;
                this.gettingAttributes = true;
                this.gettingAttributesChange.emit(this.gettingAttributes);
                this.runQuestions(repairItem);

            });
        }
    }

    runQuestions(repairItem: WorkOrderLineAuthorizationRepairItem) {
        const angularFunctions = getAngularExecutors();
        const sharedFunctions = getSharedExecutors();

        const allFunctions = sharedFunctions.concat(angularFunctions);
        const functionRunner = new FunctionRunner(allFunctions, null, null, (result, final) => {
            if (final) {
                this.gettingAttributes = false;
                this.gettingAttributesChange.emit(this.gettingAttributes);
                repairItem.selectedAttributes = result;
                this.attributesComplete.emit(repairItem);
            }
        }, 'angular');
        functionRunner.setupFunctionQuestionRender = async runner => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.functionQuestionRendererComponent.reset();
                    functionRunner.questionRenderer = this.functionQuestionRendererComponent.renderer;
                    resolve();
                });
            });
        }

        functionRunner.runProcess(repairItem.attributesDefinition, true);
    }

    openPanel() {
        this.inputAutoComplete.openPanel();
    }
}
