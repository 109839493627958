<div *ngIf="!wizardStyle" style="height: 100%;display: flex; flex-direction: column;"
    [class.drag-target-active]="dragTargetActive" (dragenter)="dragEnter($event)" (drop)="drop($event)"
    (dragover)="dragover($event)" (dragleave)="dragleave($event)">

    <div [id]="scollId" style="flex: 1;overflow-x: hidden;overflow-y: visible;">
        <div class="drop-prompt" *ngIf="dragTargetActive">
            <div class="drop-prompt-inner-box">
                <i class="material-icons page-icon">cloud_upload</i>
                <h1 style="text-align: center; ">Drop your files here to upload</h1>
            </div>
        </div>
        <div class="center">
            <ng-container *ngIf="iconClass">

                <mat-icon style="font-size: 48px;
                height: 48px;
                width: 48px;">{{iconClass}}</mat-icon>

            </ng-container>

            <p class="info" *ngIf="description">{{description}}</p>
        </div>
        <ng-container *ngFor="let group of renderer.groups">
            <ng-container *ngIf="group.questions">
                <div class="question-container fade-in-up"
                    [class.no-border]="question?.functionCell?.type === '/assets/images/function-icons/lottie-file.png' || question?.functionCell?.type === '/assets/images/function-icons/heading.png'"
                    [class.full-width]="renderer.isFullWidthQuestion(question)"
                    *ngFor="let question of group.questions">


                    <ng-template *ngTemplateOutlet="questions; context: {question: question}"></ng-template>

                </div>
            </ng-container>
            <div class="no-messages"
                *ngIf="renderer && renderer.chatting && (!group.chatMessages || group.chatMessages.length === 0) && renderer.isLastGroup(group)">
                <mat-icon>question_answer</mat-icon>

            </div>
            <ng-container *ngIf="group.chatMessages">


                <div id="message-pane">
                    <div class="message-pane-inner">

                        <div *ngFor="let message of group.chatMessages" [class.outgoing-grid]="message.internal"
                            [class.incoming-grid]="!message.internal">
                            <div *ngIf="!message.internal && !message.informational && message.entityId !== 'bot'"
                                style="text-align: center" class="message-meta">
                                <img [src]="getAvatarUrl(message)">
                                <div class="message-date">
                                    {{message.displayName}}<br>
                                    {{message.date | date: 'shortDate'}}<br>
                                    {{message.date | date: 'shortTime'}}
                                </div>
                            </div>
                            <div *ngIf="message.entityId === 'bot'" style="text-align: center;" class="message-meta">
                                <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                                <div class="message-date">
                                    {{message.displayName}}<br>
                                    {{message.date | date: 'shortDate'}}<br>
                                    {{message.date | date: 'shortTime'}}
                                </div>
                            </div>
                            <div *ngIf="message.internal || message.informational"></div>
                            <div>
                                <div class="conversation-bubble" [class.informational]="message.informational"
                                    [class.new-message]="message.newMessage" [class.incoming]="!message.internal"
                                    [class.outgoing]="message.internal">
                                    <pre
                                        class="pre-message"><ng-container *ngIf="!message.messageTokens">{{message.message}}</ng-container><ng-container *ngIf="message.messageTokens"><ng-container *ngFor="let token of message.messageTokens"><span *ngIf="!token.message && !token.url && !token.email  && !token.tel">{{token}}</span><span *ngIf="token.message"><a class="intent-link" mat-button mat-raised-button (click)="renderer.tokenClicked(token, $event, group)">{{token.message}}</a></span><span *ngIf="token.email"><a class="chat-url" href="mailto:{{token.email}}">{{token.email}}</a></span><span *ngIf="token.tel"><a class="chat-url" href="tel:{{token.tel}}">{{token.tel}}</a></span> <span *ngIf="token.url"><a [href]="token.url" class="chat-url" target="_blank">{{token.url}}</a></span></ng-container></ng-container></pre>
                                    <div *ngIf="message.attachments && message.attachments.length">
                                        <div *ngFor="let attachment of message.attachments"
                                            class="attachment-container">
                                            <img *ngIf="attachment.isImage" [src]="attachment.base64"
                                                class="attachment-thumb">
                                            <mat-icon style="height: 80px;width: 80px;font-size: 80px"
                                                *ngIf="!attachment.isImage">
                                                description
                                            </mat-icon>
                                            <br>
                                            <span *ngIf="!attachment.isImage">{{attachment.name}}</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="message.internal" class="message-meta" style="text-align: center">


                                <div class="message-date">
                                    You<br>
                                    {{message.date | date: 'shortDate'}}<br>
                                    {{message.date | date: 'shortTime'}}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <div *ngIf="renderer.agentIsTyping" class="typing-notification">
            <!-- <app-typing-notification></app-typing-notification> -->
            <lottie-player style="height: 60px"
                src="https://lottie.host/f61e1186-07ea-4eff-b1f2-b6e8f9d15f3e/okW8XSXYcZ.json" loop
                background="transparent" speed="1" autoplay></lottie-player>

            Agent Responding
        </div>
    </div>

    <div style="flex:none;padding: 10px" [class.encircle]="renderer.attachments && renderer.attachments.length"
        *ngIf="renderer && renderer.chatting" topmargin20>
        <ng-container *ngIf="renderer && renderer.chatting">


            <div>
                <div class="attachments-container-container" *ngIf="renderer.attachments && renderer.attachments.length"
                    bottommargin20>
                    <div class="attachments-container">
                        <div *ngFor="let attachment of renderer.attachments" class="attachment-container">
                            <button mat-button class="remove-button" (click)="removeAttachment(attachment)"><i
                                    class="material-icons">delete</i></button>
                            <img *ngIf="attachment.isImage" [src]="attachment.base64" class="attachment-thumb">
                            <mat-icon style="height: 49px;width: 49px;font-size: 49px" *ngIf="!attachment.isImage">
                                description
                            </mat-icon>
                            <br>
                            <span *ngIf="!attachment.isImage">{{attachment.name}}</span>

                        </div>
                    </div>
                </div>
                <form>
                    <div>
                        <div> <mat-form-field appearance="outline" class="no-fixed">
                                <mat-label>Message</mat-label>

                                <textarea matInput cdkTextareaAutosize name="new-message"
                                    (keypress)="handleKeyPress($event)" [cdkAutosizeMaxRows]="3"
                                    [(ngModel)]="renderer.newChatMessage">

                                </textarea>
                            </mat-form-field></div>
                        <div aflex>
                            <div>
                                <button mat-button mat-raised-button color="primary" class="small-primary"
                                    (click)="renderer.sendChatMessage()"
                                    [disabled]="!renderer.newChatMessage || renderer.sendingChatMessage">
                                    <mat-spinner *ngIf="renderer.sendingChatMessage" class="thirty"></mat-spinner>
                                    <mat-icon *ngIf="!renderer.sendingChatMessage">send</mat-icon>
                                </button>

                                <button (click)="showFileUpload()" mat-button>
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                            </div>
                            <div nogrow>
                                <button mat-button (click)="renderer.endChat()" [disabled]="renderer.endingChat">
                                    <mat-icon *ngIf="!renderer.endingChat">highlight_off</mat-icon>
                                    <mat-spinner *ngIf="renderer.endingChat" class="thirty"></mat-spinner>
                                    End </button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>

        </ng-container>
    </div>
</div>






<ng-container *ngIf="wizardStyle">
    <mat-tab-group class="hide-tabs" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First" *ngFor="let question of renderer.questions">

            <div aflex>
                <div>
                    <button mat-button *ngIf="question != renderer.questions[0]"
                        (click)="selectedIndex = selectedIndex - 1">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                </div>
                <div nogrow>
                    <button mat-button mat-raised-button class="small-primary" color="primary"
                        [disabled]="!question.answer && question.required"
                        *ngIf="!renderer.getShouldHideNextButton(question)" (click)="advance(question)">Next
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </div>

            <div topmargin40>
                <ng-template *ngTemplateOutlet="questions; context: {question: question}"></ng-template>
            </div>

        </mat-tab>
    </mat-tab-group>
</ng-container>

<ng-template #questions let-question="question">
    <ng-container
        *ngIf="question.functionCell.type === typeMap.singleSelect && renderer.getAnswers(question).length > 12">
        <mat-form-field appearance="outline">
            <mat-label>{{renderer.getLabel(question)}}</mat-label>
            <mat-select [(ngModel)]="question.answer" (ngModelChange)="renderer.answerQuestion($event, question)">
                <mat-option *ngFor="let answer of renderer.getAnswers(question)" [value]="answer">{{answer}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </ng-container>

    <ng-container
        *ngIf="question.functionCell.type === typeMap.singleSelect && renderer.getAnswers(question).length <= 12">

        <div aflex style="text-align: center;">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span [innerHTML]="renderer.getLabel(question)"></span>
                </p>
            </div>
        </div>
        <button mat-button mat-raised-button class="toggle-button single-select-btn"
            [disabled]="!renderer.isQuestionInLastGroup(question)" [class.offset]="offset"
            [class.selected]="question.answer === answer" (click)="renderer.setAnswer(answer,question)"
            *ngFor="let answer of renderer.getAnswers(question)">
            {{answer}}
        </button>


    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.showMessage">
        <div aflex style="text-align: center;">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span [innerHTML]="renderer.getLabel(question)"></span>
                </p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.checkCircleAnimated">
        <div class="center">
            <app-check-circle-animated [height]="renderer.getCircleHeight(question)"></app-check-circle-animated>
            <div *ngIf="renderer.getLabel(question)" class="info">{{renderer.getLabel(question)}}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.lottieFile">
        <div class="center">
            <lottie-player *ngIf="renderer.getInput(question, 1)"
                [style.height]="renderer.getInput(question, 2) ? renderer.getInput(question, 2) : 'unset'"
                src="{{renderer.getInput(question, 0)}}" loop
                [attr.intermission]="renderer.getInput(question, 3) ? renderer.getInput(question, 3) : 0"
                background="transparent" speed="1" autoplay></lottie-player>

            <lottie-player *ngIf="!renderer.getInput(question, 1)"
                [style.height]="renderer.getInput(question, 2) ? renderer.getInput(question, 2) : 'unset'"
                src="{{renderer.getInput(question, 0)}}"
                [attr.intermission]="renderer.getInput(question, 3) ? renderer.getInput(question, 3) : 0"
                background="transparent" speed="1" autoplay></lottie-player>
            <div *ngIf="renderer.getLabel(question)" class="info">{{renderer.getLabel(question)}}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.findPolicy">
        <app-search-chip-selection-v2 [showPolicyView]="true" searchType="Policy" searchSubType=""
            [placeholder]="renderer.getLabel(question)"
            (selectedResultsChange)="renderer.answerQuestionDelayed($event, question)"
            [(selectedResults)]="question.answer"></app-search-chip-selection-v2>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.emailBox">
        <mat-form-field appearance="outline">
            <mat-label>{{renderer.getLabel(question)}}</mat-label>
            <input matInput type="email" [(ngModel)]="question.answer"
                (ngModelChange)="renderer.answerQuestionDelayed($event, question)">
        </mat-form-field>
    </ng-container>

    <div class="center" *ngIf="question.functionCell.type === typeMap.confirmButton">
        <button #btn (click)="confirmClick(question, btn)" class="confirm-button"
            [disabled]="working || !renderer.isLastQuestion(question)" mat-button mat-raised-button color="primary">
            <mat-spinner *ngIf="working" class="thirty"></mat-spinner>
            {{renderer.getLabel(question)}}
        </button>
    </div>

    <ng-container *ngIf="question.functionCell.type === typeMap.phoneLink">
        <div class="data-label">{{renderer.getLabel(question)}}</div>
        <div aflex>
            <div>
                <app-phone-link icon="phone_forwarded" (dialed)="renderer.setAnswer(true,question)"
                    [alwaysShowNumber]="true" [phoneNumber]="renderer.getInput(question,1)"
                    [autoDial]="renderer.getInput(question,3)" [phoneNumberDescription]="renderer.getInput(question,2)">
                </app-phone-link>
            </div>
            <div nogrow>
                <button mat-button (click)="renderer.setAnswer(false,question)">
                    <div>
                        Skip
                        <mat-icon>chevron_right</mat-icon>
                    </div>
                </button>
            </div>
        </div>


    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.heading">
        <h1>{{renderer.getLabel(question)}}</h1>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.separator">
        <hr>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.textBox">
        <mat-form-field appearance="outline">
            <mat-label>
                {{renderer.getLabel(question)}}
            </mat-label>
            <input matInput [(ngModel)]="question.answer"
                (ngModelChange)="renderer.answerQuestionDelayed($event, question)">
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.richText">
        <div class="data-label">{{renderer.getLabel(question)}}</div>
        <app-rich-text-and-snippet [(ngModel)]="question.answer"
            (ngModelChange)="renderer.answerQuestionDelayed($event, question)"></app-rich-text-and-snippet>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.checkList">

        <div class="data-label">{{renderer.getLabel(question)}}</div>

        <mat-checkbox [(ngModel)]="answer.selected" class="wrap" (ngModelChange)="renderer.updateListAnswers(question)"
            *ngFor="let answer of renderer.getSelectableAnswers(question)">
            {{answer.answer}}
        </mat-checkbox>
        @if(renderer.getInput(question,1)) {
        <div class="center">
            <button mat-button mat-raised-button (click)="renderer.completeListAnswers(question)">
                <div>
                    Next
                    <mat-icon>chevron_right</mat-icon>
                </div>
            </button>
        </div>
        }
    </ng-container>

    @if(question.functionCell.type === typeMap.authoItemSelection) {
    <div aflex style="text-align: center;">
        <div nogrow>
            <div class="message-meta">
                <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                <div class="message-date">
                    Elevate says
                </div>
            </div>
        </div>
        <div class="comment-container">
            <p class="triangle-border">
                <span [innerHTML]="renderer.getLabel(question)"></span>
            </p>
        </div>
    </div>
    <div *ngFor="let answer of renderer.getAuthorizationItemSelectionAnswers(question)">
        <mat-checkbox [(ngModel)]="answer.selected">
            {{answer.name}}
        </mat-checkbox>
    </div>

    <div class="center">
        <button mat-button mat-raised-button [disabled]="question.saving"
            (click)="renderer.completeAuthoItemSelection(question)">
            <div>
                <mat-spinner *ngIf="question.saving" class="thirty"></mat-spinner>
                Next
                <mat-icon>chevron_right</mat-icon>
            </div>
        </button>
    </div>
    }

    <ng-container *ngIf="question.functionCell.type === typeMap.checkBox">
        <mat-checkbox [(ngModel)]="question.answer" class="wrap"
            (ngModelChange)="renderer.answerQuestion($event, question)">
            {{renderer.getLabel(question)}}
        </mat-checkbox>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.starRating">
        <div style="min-height: 78px">
            <div class="data-label">{{renderer.getLabel(question)}}</div>
            <app-star-rating [(value)]="question.answer"
                (valueChange)="renderer.answerQuestion($event, question)"></app-star-rating>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.searchChipSelection">
        <app-search-chip-selection-v2 [(selectedResults)]="question.answer"
            [searchType]="renderer.getInput(question, 0)" [searchSubType]="renderer.getInput(question, 1)"
            [placeholder]="renderer.getInput(question, 2)" [singleSelect]="renderer.getInput(question,3)"
            [showWorkOrderView]="renderer.getInput(question, 0)?.toLowerCase() === 'workorder'"
            [showPolicyView]="renderer.getInput(question, 0)?.toLowerCase() === 'policy'"
            (selectedResultsChange)="renderer.answerQuestionDelayed($event, question)">

        </app-search-chip-selection-v2>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.dateBox">
        <app-date-picker [(date)]="question.answer" (dateChange)="renderer.answerQuestion($event, question)"
            [min]="renderer.getInput(question, 2)" [max]="renderer.getInput(question, 3)"
            placeholder="{{renderer.getLabel(question)}}">

        </app-date-picker>
    </ng-container>
    <ng-container *ngIf="question.functionCell.type === typeMap.numberBox">
        <div aflex style="text-align: center;">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span [innerHTML]="renderer.getLabel(question)"></span>
                </p>
            </div>
        </div>
        <mat-form-field appearance="outline" style="max-width: 200px">
            <input matInput type="number" [(ngModel)]="question.answer"
                (ngModelChange)="renderer.answerQuestionDelayed($event, question)">
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="question.functionCell.type === typeMap.multiLineTextBox">
        <div aflex style="text-align: center;">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span [innerHTML]="renderer.getLabel(question)"></span>
                </p>
            </div>
        </div>
        <mat-form-field appearance="outline" class="no-fixed">
            <textarea rows="5" matInput type="number" [(ngModel)]="question.answer"
                (ngModelChange)="renderer.answerQuestionDelayed($event, question)"></textarea>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.showImage">
        <div class="center">
            <img class="img" [style.height]="renderer.getImageHeight(question)" [src]="renderer.getImageUrl(question)">
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.youTube">
        <iframe style="width: 100%;min-height: 300px;" frameBorder="0" allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
            [src]="getSafeUrl(question)">
        </iframe>
    </ng-container>
    <ng-container *ngIf="question.functionCell.type === typeMap.uploadImage">
        <div *ngIf="renderer.isLastQuestion(question)">
            <app-swapable-image [(url)]="question.answer" prompt="{{renderer.getLabel(question)}}" iconClass="image"
                (urlChange)="renderer.answerUploadImageQuestion($event, question)" [blockStyle]="true">
            </app-swapable-image>
            <button mat-button *ngIf="!question.inputs.inputs[0]" (click)="renderer.answerQuestion('', question)">
                <div>
                    Skip <mat-icon>chevron_right</mat-icon>
                </div>
            </button>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <div class="center">
                <div>
                    <img [src]="question.answer[0].base64" *ngIf="question.answer" style="height: 60px;">
                    <img *ngIf="!question.answer" style="height: 60px;"
                        src="https://elevateh.blob.core.windows.net/cdn/images/no-image.svg">
                </div>
                <button mat-button (click)="renderer.editQuestion(question)">
                    <mat-icon>edit</mat-icon> Change</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.brand">
        <mat-form-field appearance="outline">
            <mat-label>{{renderer.getLabel(question)}}</mat-label>
            <mat-select [(ngModel)]="question.answer" (ngModelChange)="renderer.answerQuestion($event, question)">
                <mat-option *ngFor="let brand of brands" [value]="brand">{{brand.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.slide">
        <div class="data-label">{{renderer.getLabel(question)}}</div>
        <mat-slider [(ngModel)]="question.answer" [max]="question.inputs.inputs[4]" [min]="question.inputs.inputs[3]"
            (ngModelChange)="renderer.answerQuestionDelayed($event, question)"></mat-slider>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.tagEntry">
        <app-tag-chip-selection [(selectedTags)]="question.answer"
            (selectedTagsChange)="renderer.answerQuestionDelayed($event, question)"
            [placeholder]="renderer.getLabel(question)" [viewWorkOrderTags]="question.inputs.inputs[1]"
            [viewPolicyTags]="question.inputs.inputs[2]" [viewEntityTags]="question.inputs.inputs[3]"
            [viewTaskTags]="question.inputs.inputs[4]" [viewContractorInvoiceTags]="question.inputs.inputs[5]"
            [viewPurchaseOrderTags]="question.inputs.inputs[6]" [viewInvoiceTags]="question.inputs.inputs[7]">
        </app-tag-chip-selection>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.preferredTimeSlot">
        <div [class.outgoing-grid]="true">
            <div style="text-align: center" class="message-meta">
                <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                <div class="message-date">
                    Elevate Says<br>
                </div>
            </div>
            <div>
                <div class="conversation-bubble" [class.incoming]="true">
                    <pre class="pre-message">{{renderer.getLabel(question)}}</pre>

                </div>
            </div>
        </div>

        <div *ngIf="renderer.isLastQuestion(question)">
            <app-appointment-selection [(selectedTimeSlots)]="renderer.state[question.functionCell.id]"
                [selectedItemId]="question.inputs.inputs[1]" [timeSlotDayBuffer]="question.inputs.inputs[2]"
                [hideThird]="question.inputs.inputs[3]" [loadDefaults]="true">
            </app-appointment-selection>
            <div class="center" topmargin20>
                <button mat-raised-button class="small-primary" color="primary"
                    [disabled]="!renderer.isTimeslotsFilledout(question) || !renderer.isLastQuestion(question)"
                    (click)="renderer.answerTimeslotQuestion(renderer.state[question.functionCell.id], question)"
                    mat-button>Next</button><br><br>

                <div> - OR -</div>

                <button mat-button mat-raised-button (click)="renderer.answerQuestion([], question)">
                    <mat-icon>call</mat-icon> Call Me To Schedule
                </button>

            </div>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <ul>
                <li *ngFor="let timeslot of question.answer">
                    {{timeslot.summary}}
                </li>
            </ul>
            <div class="center">
                <button mat-button (click)="renderer.editQuestion(question)"><mat-icon>edit</mat-icon> Change</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.getJobItem">
        <div class="fade-in-up" aflex style="text-align: center">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question)}}</span>
                </p>
            </div>
        </div>
        <div *ngIf="renderer.isLastQuestion(question)">
            <app-job-item-selection [(selectedItem)]="question.answer" [condensedMode]="true"
                (selectedItemChange)="renderer.answerQuestion($event, question, true)"
                [policyId]="question.inputs.inputs[0]"></app-job-item-selection>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <div class="center">
                <img style="height: 60px;" [src]="renderer.getItemUrl(question.answer)">
                <h2>{{question.answer.name}}</h2>
                <button mat-button (click)="renderer.editQuestion(question)">
                    <mat-icon>edit</mat-icon>
                    Change</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.jobItemQuestions">
        <div class="fade-in-up" aflex style="text-align: center">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question)}}</span>
                </p>
            </div>
        </div>

        <div *ngIf="renderer.isLastQuestion(question)">
            <div style="margin-top: -40px">
                <app-question-wizard-v2 (questionAnswered)="renderer.questionAnswered()" [hideItemPicture]="true"
                    [inverseQuestionOrder]="false" [(allQuestions)]="renderer.state[question.functionCell.id]"
                    (allQuestionsChange)="renderer.answerQuestion($event, question)" [showGenericProfilePic]="true"
                    [useRobotProfile]="true" [workOrderItemId]="question.inputs.inputs[0]"></app-question-wizard-v2>
            </div>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <ul>
                <ng-container *ngFor="let qanda of question.answer">
                    <li *ngIf="!qanda.hidden">
                        <span class="data-label">{{qanda.text}}</span>: {{qanda.answer}}
                    </li>
                </ng-container>
            </ul>
            <div class="center">
                <button mat-button (click)="renderer.editQuestion(question)">
                    <mat-icon>edit</mat-icon>
                    Change</button>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.getPolicies">

        <div *ngIf="renderer.isLastQuestion(question)">
            <div class="fade-in-up" aflex style="text-align: center;"
                *ngIf="renderer.currentFindPolicyStep === 'enter-code'">
                <div nogrow>
                    <div class="message-meta">
                        <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                        <div class="message-date">
                            Elevate says
                        </div>
                    </div>
                </div>
                <div class="comment-container">
                    <p class="triangle-border">
                        <span>Enter the code you received via SMS Text or Email</span>
                    </p>
                </div>
            </div>
            <div class="fade-in-up" aflex style="text-align: center;"
                *ngIf="renderer.currentFindPolicyStep === 'request-code'">
                <div nogrow>
                    <div class="message-meta">
                        <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                        <div class="message-date">
                            Elevate says
                        </div>
                    </div>
                </div>
                <div class="comment-container">
                    <p class="triangle-border">
                        <span>We can lookup your subscription using the phone number or email address attached to your
                            account.</span>
                    </p>
                </div>
            </div>

            <div class="fade-in-up" aflex style="text-align: center"
                *ngIf="renderer.currentFindPolicyStep === 'choose-policy'">
                <div nogrow>
                    <div class="message-meta">
                        <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                        <div class="message-date">
                            Elevate says
                        </div>
                    </div>
                </div>
                <div class="comment-container">
                    <p class="triangle-border">
                        <span>We found these subscriptions</span>
                    </p>
                </div>
            </div>
            <app-find-user-polices-by-phone-or-email [(selectedPolicy)]="question.answer"
                (selectedPolicyChange)="renderer.answerQuestion($event, question, true)"
                (currentStepChange)="renderer.scrollToBottom()"
                [(currentStep)]="renderer.currentFindPolicyStep"></app-find-user-polices-by-phone-or-email>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <div class="center">
                <img [src]="renderer.getPropertyThumbnail(question.answer)">
                <h2>{{question.answer.address1}}</h2>
                <p>{{question.answer.planName}}</p>
                <p>
                    <span class="badge">{{question.answer.status}}</span>
                </p>
                <button mat-button (click)="renderer.editQuestion(question)">
                    <mat-icon>edit</mat-icon>
                    Change</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.workOrderItemTypeSelection">
        <div class="fade-in-up" aflex style="text-align: center">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question, 'Select the item type')}}</span>
                </p>
            </div>
        </div>
        <app-select-work-order-item-type [authoInput]="question.inputs.objectInScope"  (workOrderItemTypeIdChange)="renderer.answerQuestion($event, question)"></app-select-work-order-item-type>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.selectRepairType">
        <div class="fade-in-up" aflex style="text-align: center">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question, 'Select the repair type')}}</span>
                </p>
            </div>
        </div>
         <app-select-repair-type [authoInput]="question.inputs.objectInScope" (repairTypeIdChange)="renderer.answerQuestion($event, question)"></app-select-repair-type>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.getCreditCards">
        <div class="fade-in-up" aflex style="text-align: center">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question)}}</span>
                </p>
            </div>
        </div>
        <div *ngIf="renderer.isLastQuestion(question)">

            <app-payment-method-entry [customerId]="question.inputs.inputs[0]"
                [(selectedCard)]="renderer.state[question.functionCell.id]"></app-payment-method-entry>

            <div class="center" topmargin20>
                <button mat-raised-button class="small-primary" color="primary"
                    [disabled]="!renderer.state[question.functionCell.id] || !renderer.isLastQuestion(question)"
                    (click)="renderer.answerQuestion(renderer.state[question.functionCell.id], question)" mat-button>Use
                    <span *ngIf="renderer.state[question.functionCell.id]">
                        {{renderer.state[question.functionCell.id].brand}}
                        {{renderer.state[question.functionCell.id].last4}}
                    </span>
                </button>
            </div>

        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">


            <div class="center">
                <img *ngIf="question.answer.brand == 'Visa'"
                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/visa.png" class="card-icon">
                <img *ngIf="question.answer.brand == 'Discover'"
                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/discover.png"
                    class="card-icon">
                <img *ngIf="question.answer.brand == 'American Express'"
                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/americanexpress.png"
                    class="card-icon">
                <img *ngIf="question.answer.brand == 'MasterCard'"
                    src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/mastercard.png"
                    class="card-icon">
                <h2>{{question.answer.brand}} {{question.answer.last4}}</h2>
                <button mat-button (click)="renderer.editQuestion(question)"><mat-icon>edit</mat-icon> Change</button>
            </div>
        </div>

    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.hyperlink">
        <div class="fade-in-up" aflex style="text-align: center" *ngIf="renderer.getLabel(question)">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question)}}</span>
                </p>
            </div>
        </div>
        <a style="cursor: pointer;color: #198cf0" (click)="renderer.showHyperlink(question)">
            {{question.inputs.inputs[1] ? question.inputs.inputs[1] : question.inputs.inputs[0]}}
        </a>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.addressEntry">
        <div *ngIf="renderer.isLastQuestion(question)">
            <div bottommargin20 *ngIf="renderer.getLabel(question)" class="data-label">{{renderer.getLabel(question)}}
            </div>
            <app-address-editor [initializeAddress]="true" [(address)]="renderer.state[question.functionCell.id]"
                [loadPropertyMeta]="question.inputs.inputs[1]" [showPropertyFields]="question.inputs.inputs[1]"
                [(addressComplete)]="renderer.state[question.functionCell.id + '-complete']"></app-address-editor>
            <button mat-button mat-raised-button color="primary"
                [disabled]="!renderer.state[question.functionCell.id + '-complete']"
                (click)="renderer.answerQuestion(renderer.state[question.functionCell.id], question)">Next</button>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <div bottommargin20 *ngIf="renderer.getLabel(question)" class="data-label">{{renderer.getLabel(question)}}
            </div>

            <div *ngIf="question.answer">
                <h2>{{question.answer.address1}}</h2>
                <div *ngIf="question.answer.address2">{{question.answer.address2}}</div>
                <div>
                    {{question.answer.city}}, {{question.answer.state}} {{question.answer.postalCode}}
                </div>
            </div>
            <div class="center">
                <button mat-button (click)="renderer.editQuestion(question)"><mat-icon>edit</mat-icon> Change</button>
            </div>
        </div>


    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.planSelection">
        <div *ngIf="renderer.isLastQuestion(question)">
            <app-plan-offerings [newConstruction]="question.inputs.inputs[3]" [scaleSmall]="true"
                [(selectedPlan)]="question.answer"
                (selectedPlanChange)="renderer.answerQuestion($event, question, true)"
                [isMonthly]="question.inputs.inputs[2]" [address]="question.inputs.inputs[1]"
                [coverageType]="question.inputs.inputs[0]"></app-plan-offerings>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">


            <div *ngIf="question.answer">
                <h2>{{question.answer.name}}</h2>
            </div>
            <div class="center">
                <button mat-button (click)="renderer.editQuestion(question)"><mat-icon>edit</mat-icon> Change</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="question.functionCell.type === typeMap.confirmContact">
        <div class="fade-in-up" aflex style="text-align: center">
            <div nogrow>
                <div class="message-meta">
                    <img src="https://uklprodstorage.blob.core.windows.net/doc-public/cdn/images/bot.svg">
                    <div class="message-date">
                        Elevate says
                    </div>
                </div>
            </div>
            <div class="comment-container">
                <p class="triangle-border">
                    <span>{{renderer.getLabel(question)}}</span>
                </p>
            </div>
        </div>
        <div *ngIf="renderer.isLastQuestion(question)">
            <app-confirm-job-contact-info [entityId]="question.inputs.inputs[0]" [(entitySummary)]="question.answer"
                (entitySummaryChange)="renderer.answerQuestion($event, question)"></app-confirm-job-contact-info>
        </div>
        <div *ngIf="!renderer.isLastQuestion(question)">
            <div class="data-label">Name</div>
            <div>{{question.answer.name}}</div>
            <div class="data-label" topmargin20>Email</div>
            <div>{{question.answer.email}}</div>
            <div class="data-label" topmargin20>Phone</div>
            <div>{{question.answer.homeNumber | formatPhoneNumber}}</div>
            <div class="center">
                <button mat-button (click)="renderer.editQuestion(question)">
                    <mat-icon>edit</mat-icon>
                    Change</button>
            </div>
        </div>
    </ng-container>

</ng-template>

<input type="file" style="display: none" name="fileToUpload1" (change)="handleFileUpload($event)" id="fileToUpload1" />