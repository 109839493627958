<div class="container" style="padding-top: 60px">

    <div *ngIf="customerProperty">


        <h1 class="warning" *ngIf="customerProperty.status === 'Suspended'">
            Customer Suspended
        </h1>
        <div flex>
            <mat-card bottommargin20>

                <h2 class="sub-heading" bottommargin20>Property Information</h2>
                <div class="center">
                    <img [src]="propertyUrl" style="height: 100px;">
                </div>
                <address>
                    {{customerProperty.propertyAddress1}}
                    <span *ngIf="customerProperty.propertyAddress2"><br>{{customerProperty.propertyAddress2}}</span>

                    <br>
                    {{customerProperty.propertyCity}}, {{customerProperty.propertyState}}
                    {{customerProperty.propertyPostalCode}}
                    <div aflex>
                        <div flex1>
                            <div class="data-label">Bedrooms</div>
                            {{customerProperty.bedroomCount}}
                        </div>
                        <div flex1>
                            <div class="data-label">Bathrooms</div>
                            {{customerProperty.bathroomCount}}
                        </div>
                    </div>
                </address>
            </mat-card>

            <mat-card bottommargin20>
                <h2 class="sub-heading" bottommargin20>Customer Information</h2>
                <div>{{customerProperty.customerName}}</div>

                <div style="overflow: hidden;">
                    {{customerProperty.customerEmail}}
                </div>
                <div>
                    {{customerProperty.customerPhoneNumber | formatPhoneNumber}}
                </div>
                <div>
                    <app-sms-link-button [buttonMode]="true" [remoteEntityId]="customerProperty.customerId"
                        label="Text {{customerProperty.customerPhoneNumber | formatPhoneNumber}}"
                        [number]="customerProperty.customerPhoneNumber"></app-sms-link-button>

                </div>
            </mat-card>
        </div>

        <div class="services">
            <div class="plan-selection-outer-container">
                <div class="plan-selection-container">
                    <div *ngFor="let service of services" class="plan">
                        <div class="flip-container" [class.flip-it]="service.flipped">
                            <div class="flipper">

                                <div class="front" [style.background-image]="getBackground(service)">
                                    <div class="service-container">
                                        <div>
                                            <h2 class="category-name-h2" [style.color]="service.color">
                                                {{service.name}}</h2>
                                            <div class="data-label" *ngIf="service.cost" style="margin-top: 20px;">
                                                Price / Visit</div>
                                            <h2 class="price" *ngIf="service.cost">${{service.cost}}</h2>

                                            <ng-container *ngIf="service.costPerMonth">
                                                <div class="data-label">Price / Month</div>
                                                <h2 class="price">${{service.costPerMonth}}</h2>
                                            </ng-container>
                                            <div>
                                                {{service.frequencyName}}
                                            </div>
                                            <div>
                                                {{service.preferredDayName}}
                                            </div>


                                            <br>
                                            <button mat-button mat-raised-button class="plan-detail-btn"
                                                (click)="select(service)">View</button>

                                        </div>

                                        <div>
                                            <div class="summary" *ngIf="service.shortSummary">
                                                {{service.shortSummary}}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="back">

                                    <button class="close-detail-button" (click)="service.flipped = false">
                                        <mat-icon>chevron_left</mat-icon><br> Back
                                    </button>

                                    <h2 [style.color]="service.color" [style.border-bottom-color]="service.color"
                                        style="border-bottom-style: solid;border-bottom-width: 1px;padding-bottom: 5px;margin-bottom: 15px">
                                        {{service.name}}</h2>
                                    <div style="text-align: left;" [innerHTML]="service.contractLanguage">

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="cancelledServices && cancelledServices.length">
            <h2 class="sub-heading">
                Cancelled Services
            </h2>

            <ul>
                <li *ngFor="let service of cancelledServices">
                    {{service.name}} - Cancelled: {{service.cancellationDate | date: 'shortDate'}}
                </li>
            </ul>
        </div>

        <div *ngIf="addOnRequests && addOnRequests.length">
            <h2 class="sub-heading">Add-On Requests</h2>
            <ul>
                <li *ngFor="let addOnRequest of addOnRequests">
                    <a routerLink="/pending-add-on/{{addOnRequest.id}}">{{addOnRequest.createdDate | date:
                        'shortDate'}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<app-maint-services-nav-bar #maintBar></app-maint-services-nav-bar>


<div id="service-config-panel" *ngIf="selectedService">
    <button mat-button class="close-detail-button-2" (click)="selectedService = null">
        <mat-icon>close</mat-icon>
    </button>

    <div
        style="overflow-x: hidden;overflow-y: auto;height: 100%;margin-top: -59px; padding-top: 20px;padding-right: 10px; padding-left: 5px">
        <div class="center">
            <div
                style="border-radius: 50%; height: 150px; width: 150px;overflow:hidden;display: inline-block;border: 1px solid #ccc">
                <img [src]="getOfferingUrl(selectedService)">
            </div>
            <h2 class="category-name-h2" [style.color]="selectedService.color" style="text-align: center;">
                {{selectedService.name}}</h2>

        </div>

        <mat-tab-group>
            <mat-tab label="Appointments">
                <div class="data-label">Preferred Day</div>
                <h1 class="big-data" style="display: inline-block;">{{selectedService.preferredDayName}}</h1> <button
                    mat-button (click)="changePreferredDay()"><mat-icon>edit</mat-icon></button>
                <div class="service-apt-list" topmargin20>
                    <div *ngFor="let appointment of serviceAppointments">
                        <button mat-button aflex class="appt-btn"
                            (click)="appointment.selected = !appointment.selected">
                            <div aflex class="upcomming-job">
                                <div nogrow>
                                    <app-calendar-day-viewer
                                        [date]="appointment.scheduledDate"></app-calendar-day-viewer>
                                </div>
                                <div>
                                    <div>{{appointment.maintenanceServiceName}}</div>
                                    <!-- <div>{{appointment.contractorName}}</div> -->
                                    <div>{{appointment.scheduledDate | date: 'shortDate'}}
                                        @if(appointment.scheduledStartWindow && appointment.scheduledEndWindow) {
                                            {{appointment.scheduledStartWindow}}-{{appointment.scheduledEndWindow}}
                                        }
                                    </div>
                                </div>
                                <div nogrow *ngIf="appointment.completedDate">
                                    <mat-icon>done</mat-icon>
                                </div>
                                <div nogrow *ngIf="appointment.cancellationDate">
                                    <mat-icon>cancel</mat-icon>
                                </div>
                            </div>


                        </button>

                        <div *ngIf="appointment.selected" class="expand-in">
                            <button mat-button (click)="editAppointment(appointment)"
                                *ngIf="!appointment.cancellationDate">
                                <div>
                                    <mat-icon>done</mat-icon><br>
                                    Complete
                                </div>
                            </button>

                            <button mat-button (click)="cancelAppointment(appointment)"
                                *ngIf="!appointment.cancellationDate">
                                <div>
                                    <mat-icon>cancel</mat-icon><br>
                                    Cancel
                                </div>
                            </button>

                            <button mat-button (click)="reschedule(appointment)" *ngIf="!appointment.cancellationDate">
                                <div>
                                    <mat-icon>event</mat-icon><br>
                                    Reschedule
                                </div>
                            </button>

                            <button mat-button (click)="uncancelAppointment(appointment)"
                                *ngIf="appointment.cancellationDate">
                                <div>
                                    <mat-icon>undo</mat-icon>
                                    Un-Cancel
                                </div>
                            </button>
                        </div>
                    </div>

                </div>
            </mat-tab>
            <mat-tab label="Terms">
                <div class="contract-t-c" [innerHTML]="selectedService.contractLanguage"></div>
            </mat-tab>
            <mat-tab label="Cost / Price">

                <div class="two-column-grid">
                    <div *ngIf="!selectedService.costPerMonth">
                        <div class="data-label">Cost / Service</div>
                        <h1 class="big-data">{{selectedService.cost | currency}}</h1>
                    </div>
                    <div *ngIf="selectedService.costPerMonth">
                        <div class="data-label">Cost / Month</div>
                        <h1 class="big-data">{{selectedService.costPerMonth | currency}}</h1>
                    </div>
                    <div>
                        <div class="data-label">Frequency</div>
                        <div>{{selectedService.frequencyName}}</div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Warranty Items" *ngIf="warrantyItems && warrantyItems.length > 0">
                <p class="info">Below are a list of available warranty items</p>
                <button mat-button *ngFor="let item of warrantyItems" (click)="startWarrantyRequest(item)">
                    <img style="height: 50px" [src]="getItemUrl(item)"><br>
                    {{item.workOrderItemName}}
                </button>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>